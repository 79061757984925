import React from "react";

import styles from "./assetRename.module.css";

const LoadingIcon = ({ loading }) => {
  if (!loading) {
    return null;
  }

  return <img className={styles.loadingIcon} src="/img/loading.svg" alt="" />;
};

export default LoadingIcon;
