import React from "react";
import { Table } from "antd";
import { orgId, token } from "../constants";

const COLUMNS = [
  {
    title: "Configuration",
    dataIndex: "configuration",
    key: "configuration",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
  {
    title: "Catalog Item",
    dataIndex: "model_url",
    key: "model_url",
    render: (modelUrl) =>
      modelUrl ? (
        <a target="_blank" rel="noopener noreferrer" href={modelUrl}>
          Link
        </a>
      ) : (
        ""
      ),
  },
  {
    title: "Task",
    dataIndex: "task_url",
    key: "task_url",
    render: (task_url) =>
      task_url ? (
        <a target="_blank" rel="noopener noreferrer" href={task_url}>
          Link
        </a>
      ) : (
        ""
      ),
  },
];

const RenderErrorsView = ({ dataSource, inModalView, jobIds }) => {
  if (dataSource.length === 0) {
    return (
      <div style={{ marginTop: 30 }}>No errors found for selected jobs</div>
    );
  }
  const jobIdsStr = (jobIds || []).join(",");

  return (
    <div style={{ marginTop: 30 }}>
      {inModalView && (
        <div>
          <a
            href={`/review-errors?ids=${jobIdsStr}&token=${token}&orgId=${orgId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Open in new tab
          </a>
        </div>
      )}

      <div style={{ marginBottom: 20, color: "red" }}>
        Errors count: {dataSource.length}
      </div>
      <Table columns={COLUMNS} dataSource={dataSource} />
    </div>
  );
};

export default RenderErrorsView;
