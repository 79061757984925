import React, { Component } from "react";
import jszip from "jszip";
import { saveAs } from "file-saver";

import Button from "../components/Button";
import { Progress } from "antd";
import { GCS_BUCKET, IMAGES_COUNT_PER_ZIP } from "../config";
import "antd/dist/antd.css";

const getSRCValue = (url) =>
  url.replace("/renders", `https://${GCS_BUCKET}.storage.googleapis.com`);

const getFileName = (title, position, total) => {
  let startPos = position * IMAGES_COUNT_PER_ZIP;
  startPos = startPos === 0 ? 1 : startPos + 1;
  let endPos = (position + 1) * IMAGES_COUNT_PER_ZIP;
  endPos = endPos >= total ? total : endPos;

  return `${title} (${startPos} - ${endPos}).zip`
}

class DownloadRendersAction extends Component {
  state = {
    isProcessingStarted: false,
    processedCount: 0,
  };

  getBase64FromUrl = (url) => {
    return new Promise((resolve) => {
      fetch(url)
        .then((data) => data.blob())
        .then((blob) => resolve(blob));
    });
  };

  saveZipFile = (data, position, total) => {
    const { title } = this.props;

    return new Promise((resolve) => {
      if (data.length === 0) {
        resolve();
        return;
      }

      const zipFile = new jszip();
      data.forEach(({ name, content }) => {
        zipFile.file(name, content, { binary: true });
      });

      zipFile.generateAsync({ type: "blob" }).then((zipContent) => {
        saveAs(zipContent, getFileName(title, position, total));
        resolve();
      });
    });
  };

  saveResults = (count, data) => {
    if (count === data.length) {
      const total = this.dataToSave.length; 
      const filesCount = parseInt(total / IMAGES_COUNT_PER_ZIP) + 1;

      for (let i = 0; i < filesCount; i++) {
        const start = i * IMAGES_COUNT_PER_ZIP;
        const end = (i + 1) * IMAGES_COUNT_PER_ZIP;

        const dataPortion = this.dataToSave.slice(start, end);
        this.saveZipFile(dataPortion, i, total);
      }
    }
  };

  handleDownloadClick = () => {
    const { data } = this.props;
    if (data.length === 0) {
      return;
    }

    let count = 0;
    this.setState({ isProcessingStarted: true });

    this.dataToSave = [];
    data.forEach(({ fileName, url }) => {
      this.getBase64FromUrl(getSRCValue(url))
        .then((fileContent) => {
          this.dataToSave.push({ name: fileName, content: fileContent });

          count++;
          this.setState({ processedCount: count });
          this.saveResults(count, data);
        })
        .catch(() => {
          count++;
          this.saveResults(count, data);
        });
    });
  };

  render() {
    const { isProcessingStarted, processedCount } = this.state;
    const { data } = this.props;

    const imagesCount = data.length;
    const percent =
      imagesCount > 0
        ? parseInt((processedCount / imagesCount) * 100)
        : 0;
    const showWarning = processedCount === imagesCount;

    return (
      <div style={{ textAlign: "left" }}>
        <Button
          text="Download as .zip"
          onClick={this.handleDownloadClick}
        />

        {isProcessingStarted && (
          <Progress
            percent={percent}
            format={(percent) =>
              `${parseInt(
                imagesCount * (percent / 100)
              )} / ${imagesCount}`
            }
          />
        )}

        {showWarning && (
          <div>
            Download will start in few seconds! Please do not close the
            tab.
          </div>
        )}
      </div>
    );
  }
};

export default DownloadRendersAction;
