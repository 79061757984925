import React, { Fragment, useEffect, useState } from "react";

import Button from "../components/Button";
import { SERVER } from "../config";
import { token, orgId } from "../constants";

import ImportProducts from "./ImportProducts";
import styles from "./assetRename.module.css";
import LoadingIcon from "./LoadingIcon";

const ITEM_NAMES = [
  "Collection Code",
  "Item Code",
  "Material Code",
  "Leg Finish Code"
];

const getCodesDefault = () => ITEM_NAMES.reduce((res, name) => {
  res[name] = "";
  return res;
}, {})

const ReplaceCodeItem = ({ name, onChange, value, newValue }) => (
  <div className={styles.fieldContainer}>
    <div>
      <div className={styles.fieldTitle}>{name}</div>
      <input
        id={name}
        className={styles.inputField}
        onChange={onChange}
        value={value}
      />
    </div>

    <div className={styles.separator}>{">"}</div>

    <div>
      <div className={styles.fieldTitle}>New {name} </div>
      <input
        id={`${name}-new`}
        className={styles.inputField}
        onChange={onChange}
        value={newValue}
      />
    </div>
  </div>
);

const ReplaceCodesSection = ({ onResultChange, reload }) => {
  const [loading, setLoading] = useState(false);
  const [codes, setCodes] = useState(getCodesDefault());
  const [newCodes, setNewCodes] = useState(getCodesDefault());

  useEffect(() => {
    if (reload) {
      setCodes(getCodesDefault());
      setNewCodes(getCodesDefault());
    }
  }, [reload])

  const searchBatches = () => {
    setLoading(true);
    fetch(`${SERVER}/api/queryBatches`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({ codes, token, orgId }),
    })
      .then((res) => res.json())
      .then((res) => {
        const result = res.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        onResultChange && onResultChange(result, codes, newCodes);
        setLoading(false);
      });
  };

  const onItemChange = (e) => {
    const { id, value } = e.target;

    if (id.indexOf("new") !== -1) {
      const name = id.replace(/-new/gi, "");
      setNewCodes({
        ...newCodes,
        [name]: value
      })
      newCodes[name] = value;
    } else {
      setCodes({
        ...codes,
        [id]: value
      })
    }
  };

  return (
    <Fragment>
      <div className={styles.header}>
        <span>Duplicate and Rename Assets</span>
      </div>

      <div className={styles.titleContainer}>
        <div className={styles.title}>Replace</div>
        <div className={styles.title}>With</div>
        <div className={styles.btnContainer}>
          <Button text="Search" onClick={searchBatches} disabled={loading} />
          <LoadingIcon loading={loading} />

          <ImportProducts />
        </div>
      </div>

      <hr className={styles.divider} />
      {ITEM_NAMES.map((name) => (
        <ReplaceCodeItem 
          key={name} 
          value={codes[name]}
          newValue={newCodes[name]} 
          name={name} 
          onChange={onItemChange} 
        />
      ))}
    </Fragment>
  );
};

export default ReplaceCodesSection;
