import React from "react";
import { Image, List } from "antd";
import "antd/dist/antd.css";

import { GCS_BUCKET } from "../config";

const getPreivewLink = (src = "") => src.replace("_preview", "");
const performUrlTitle = (url) => url.substring(url.lastIndexOf("/") + 1);
const getSRCValue = (url) =>
  url.replace("/renders", `https://${GCS_BUCKET}.storage.googleapis.com`);

const VERSION = 1;
const ImageViewItem = ({ fileName, url }) => {
  if (!url)
    if (!url) {
      return (
        <div key={fileName}>{`Image ${performUrlTitle(
          fileName
        )} does not upload successfully!`}</div>
      );
    }
  let src = getSRCValue(url);

  if (src.includes("?")) {
    src += `&version=${VERSION}`;
  } else {
    src += `?version=${VERSION}`;
  }
  return (
    <List.Item key={fileName}>
      <div
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
        key={fileName}
      >
        <Image
          width={800}
          src={src}
          preview={{
            src: getPreivewLink(src),
          }}
        />
        <div style={{ paddingBottom: "5px" }}>
          <a href={src} target="_blank" rel="noopener noreferrer">
            {performUrlTitle(url)}
          </a>
        </div>
      </div>
    </List.Item>
  );
};

export default ImageViewItem;
