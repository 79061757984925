import React, { useEffect, useState } from 'react';
import { Spin } from "antd";
import { SERVER } from '../config';
import { orgId } from "../constants";

import RenderErrorsView from './RenderErrorsView';

const ReviewErrorsView = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [renderErrors, setRenderErrors] = useState([]);

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const selected = queryParameters.get("ids").split(",");

    fetch(`${SERVER}/api/queryErrors`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        selected: selected,
        orgId,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        setRenderErrors(res);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return (
      <div style={{ height: 500, display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Spin tip="Loading" size="large">
          <div className="content" />
        </Spin>
      </div>
    );
  }

  return (
    <div style={{ marginLeft: 40, marginRight: 40 }}>
      <RenderErrorsView dataSource={renderErrors} />
    </div>
  );
};

export default ReviewErrorsView;
