import React, { useState } from 'react';
import styles from './assetRename.module.css';
import Button from '../components/Button';
import { token, orgId } from '../constants';
import { SERVER } from '../config';
import { message } from 'antd';

const DownloadSuperSilos = ({ onResultChange }) => {
  const [loading, setLoading] = useState(false);
  const [jobId, setJobId] = useState("");

  const downloadSupeSilos = async () => {
    setLoading(true);
    
    const body = JSON.stringify({ jobId, orgId, token });

    fetch(`${SERVER}/api/downloadSilos`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body,
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        if (res.STATUS === "DONE") {
          message.info(
            "Job is already processed please find it in results table",
            10
          );
        } else {
          message.success(
            "Job processing is started. Results will be saved into history table.",
            10
          );
        }

        setLoading(false);
        setJobId("");
        
        onResultChange && onResultChange();
      });
  };

  return (
    <>
      <div className={styles.header}>Download Super Silo Renders</div>
      <div className={styles.titleContainer}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div className={styles.fieldTitle} style={{ marginRight: 20 }}>
              SuperSilo Job Id
            </div>
            <input
              value={jobId}
              style={{ width: 500, marginRight: 20 }}
              className={styles.inputField}
              onChange={(e) => setJobId(e.target.value)}
            />
          </div>
          <div style={{ display: "flex" }}>
            <Button text="Download" onClick={() => downloadSupeSilos()} />
            {loading && (
              <img
                className={styles.loadingIcon}
                src="/img/loading.svg"
                alt=""
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DownloadSuperSilos;
