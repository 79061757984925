import React, { Component } from "react";
import Modal from "react-modal";

import DownloadJobs from "./DownloadJobs";
import JobsHistory from "./JobsHistory";
import DownloadHistory from "./DownloadHistory";
import SearchModal from "./SearchModal";
import RestartApp from "./RestartApp";
import UploadJobSection from "./UploadJobSection";
import ReplaceCodesSection from "./ReplaceCodesSection";
import styles from "./assetRename.module.css";
import RenameHistory from "./RenameHistory";

Modal.setAppElement("#modal");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    width: "500px",
    height: "80%",
    right: "auto",
    bottom: "auto",
    border: "1px solid grey",
    padding: "20px",
    borderRadius: 0,
    transform: "translate(-50%, -50%)",
  },
};

class Core extends Component {
  state = {
    batches: [],
    codes: {},
    newCodes: {},
    searchModalOpen: false,
    reloadUploadHistory: false,
    reloadDownloadHistory: false,
    reloadRenameHistory: false,
  };

  onUploadJobClick = (batches) => {
    this.setState({
      batches: batches,
      searchModalOpen: true,
      reloadUploadHistory: false,
      reloadDownloadHistory: false,
      reloadRenameHistory: false,
    });
  };

  onReplaceCodesClick = (batches, codes, newCodes) => {
    this.setState({
      batches: batches,
      codes,
      newCodes,
      searchModalOpen: true,
      reloadUploadHistory: false,
      reloadDownloadHistory: false,
      reloadRenameHistory: false,
    });
  };

  onDownloadSupeSiloClick = () => {
    this.setState({
      reloadUploadHistory: true,
      reloadDownloadHistory: true,
      reloadRenameHistory: true,
    });
  };

  onSearchModalClose = () => {
    this.setState({
      searchModalOpen: false,
      batches: [],
      codes: [],
      newCodes: [],
      reloadUploadHistory: true,
      reloadDownloadHistory: true,
      reloadRenameHistory: true,
    });
  };

  render() {
    const {
      batches,
      codes,
      newCodes,
      searchModalOpen,
      reloadUploadHistory,
      reloadDownloadHistory,
      reloadRenameHistory,
    } = this.state;

    return (
      <div className={styles.container}>
        <UploadJobSection onResultChange={this.onUploadJobClick} />
        <hr className={styles.divider} />

        <JobsHistory reload={reloadUploadHistory} />
        <hr className={styles.divider} />

        <DownloadJobs onResultChange={this.onDownloadSupeSiloClick} />
        <hr className={styles.divider} />

        <DownloadHistory reload={reloadDownloadHistory} />
        <hr className={styles.divider} />

        <ReplaceCodesSection
          onResultChange={this.onReplaceCodesClick}
          reload={reloadRenameHistory}
        />
        <hr className={styles.divider} />

        <RenameHistory reload={reloadRenameHistory} />
        <hr className={styles.divider} />

        <RestartApp />

        <Modal isOpen={searchModalOpen} style={customStyles}>
          <SearchModal
            {...{ batches, codes, newCodes }}
            onClose={this.onSearchModalClose}
          />
        </Modal>
      </div>
    );
  }
}

export default Core;
