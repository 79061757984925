import React, { Component } from "react";
import { List, Pagination, BackTop } from "antd";

import { fileId, jobName } from "../constants";
import { THREEKIT_SERVER } from "../config";
import ImageViewItem from "./ImageViewItem";

import DownloadRendersAction from "./DownloadRendersAction";
import styles from "./assetRename.module.css";
import "antd/dist/antd.css";

const getOriginalImage = (items) =>
  items.find(
    (i) =>
      i.fileName.indexOf("_mod.png") === -1 &&
      i.fileName.indexOf("_preview.png") === -1
  );
  
const getModImage = (items) =>
  items.find((i) => i.fileName.indexOf("_mod.png") !== -1);

const getPreviewImage = (items) =>
  items.find((i) => i.fileName.indexOf("_preview.png") !== -1);

class CorpView extends Component {
  state = {
    corpResults: [],
    corpResultsToDownload: [],
    total: 0,
    perPage: 50,
    page: 1,
  };

  componentWillMount() {
    if (!fileId) {
      return;
    }

    fetch(`${THREEKIT_SERVER}/api/files/${fileId}/content`)
      .then((res) => res.json())
      .then((json) => {
        const corpResults = [];
        const corpResultsToDownload = [];

        Object.values(json).forEach((arr) => {
          const previewImage = getPreviewImage(arr);
          const originalImage = getOriginalImage(arr);
          const modImage = getModImage(arr);

          if (previewImage) {
            corpResults.push(previewImage);
          } else {
            if (originalImage) {
              corpResults.push(originalImage);
            }
          }

          corpResultsToDownload.push(originalImage);
          corpResultsToDownload.push(modImage);
        });

        this.setState({
          corpResults,
          corpResultsToDownload,
          total: corpResults.length,
        });
      });
  }

  render() {
    const { total, corpResults, perPage, page, corpResultsToDownload } = this.state;

    return (
      <div className={styles.container}>
        {jobName && <div className={styles.header}>{`Renders for Job ${jobName}`}</div>}
        <div className={styles.pager}>
          <DownloadRendersAction data={corpResultsToDownload} title={jobName} />
          <Pagination
            current={page}
            total={total}
            perPage={perPage}
            defaultPageSize={perPage}
            showLessItems={true}
            pageSizeOptions={[20, 50, 100, 200, 500]}
            onChange={(page, perPage) => {
              this.setState({ page, perPage });
              document.body.scrollTop = 0; // For Safari
              document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
            }}
          />
        </div>
        <BackTop />
        <List
          itemLayout="vertical"
          size="large"
          pagination={{
            pageSize: perPage,
            position: "none",
            current: page,
          }}
          dataSource={corpResults}
          renderItem={({ fileName, url }) => (
            <ImageViewItem {...{ fileName, url }} />
          )}
        />
      </div>
    );
  }
}

export default CorpView;
