import React from "react";
import Button from "../components/Button";
import { SERVER } from "../config";

const RestartApp = () => {
  return (
    <div>
      <Button
        text="Restart the app"
        style={{ marginLeft: 10 }}
        onClick={async () => {
          await fetch(`${SERVER}/api/restart`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
          });
        }}
      />
      <span>
        * the app may be unavailable for a while and you can refresh the page to
        check if the app is ready.
      </span>
    </div>
  );
}

export default RestartApp;
