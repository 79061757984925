import React, { Fragment, useState } from "react";

import Button from "../components/Button";
import { SERVER } from "../config";
import { token, orgId } from "../constants";

import styles from "./assetRename.module.css";
import LoadingIcon from "./LoadingIcon";

const UploadJobSection = ({ onResultChange }) => {
  const [jobId, setJobId] = useState("");
  const [loading, setLoading] = useState(false);

  const searchJobId = () => {
    setLoading(true);
  
    fetch(`${SERVER}/api/queryJob`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({ jobId, orgId, token }),
    }).then((res) => res.json())
    .then((res) => {
      setLoading(false);
      setJobId("");
      onResultChange && onResultChange(res);
    })
  };

  return (
    <Fragment>
      <div className={styles.header}>
        <span>Upload From Job Id</span>
      </div>
      <div className={styles.titleContainer}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div className={styles.fieldTitle} style={{ marginRight: 20 }}>
              WhiteSweep Job Id
            </div>
            <input
              value={jobId}
              style={{ width: 500, marginRight: 20 }}
              className={styles.inputField}
              onChange={(e) => setJobId(e.target.value)}
            />
          </div>

          <div style={{ display: "flex" }}>
            <Button text="Search" onClick={searchJobId} />
            <LoadingIcon loading={loading} />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default UploadJobSection;
