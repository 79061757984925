import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Core from "./apps/Core";
import ArFileRename from './apps/arFileRename';
import CorpView from './apps/corpView';
import ReviewErrorsView from "./apps/ReviewErrorsView";

const App = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/ar-file-rename" component={ArFileRename} />
      <Route exact path="/crop-view" component={CorpView} />
      <Route exact path="/review-errors" component={ReviewErrorsView} />

      <Route exact path="*" component={Core} />
    </Switch>
  </BrowserRouter>
);

export default App;
