import React, { useCallback, useEffect, useRef, useState } from "react";
import { Input, Button, Space, Table } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import moment from "moment";

import styles from "./assetRename.module.css";
import { SERVER } from "../config";
import { token, orgId } from "../constants";

const COLUMNS = [
  {
    title: "Job Id",
    dataIndex: "job_id",
    key: "job_id",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
  {
    title: "Trigger Date",
    dataIndex: "start_date",
    key: "start_date",
  },
  {
    title: "Duration",
    dataIndex: "duration",
    key: "duration",
  },
  {
    title: "File URL",
    dataIndex: "file_url",
    key: "file_url",
    render: (file_url) =>
      file_url ? (
        <a href={file_url} target="_blank" rel="noopener noreferrer">
          {file_url}
        </a>
      ) : (
        ""
      ),
  },
];

const DownloadHistory = ({ reload }) => {
  const [results, setResults] = useState([]);
  const searchInput = useRef(null);
  const handleSearch = (confirm) => {
    confirm();
  };

  const fetchResults = useCallback(() => {
    fetch(`${SERVER}/api/downloadHistory?token=${token}&orgId=${orgId}`)
      .then((res) => res.json())
      .then((history) => {
        const data = history.results
          .map((i) => i.value)
          .sort((a, b) => new Date(b.start_date) - new Date(a.start_date))
          .filter((i) => i.job_id && i.status);

        setResults(data);
      });
  }, []);

  useEffect(() => {
    fetchResults();
  }, [fetchResults]);

  useEffect(() => {
    if (reload) {
      fetchResults();
    }
  }, [reload, fetchResults]);

  const dataSource = results.map((i) => {
    i.start_date = moment(new Date(i.start_date), "DD MM YYYY hh:mm:ss").format(
      "MMM DD YYYY, h:mm:ss a"
    );
    return i;
  });

  COLUMNS[0] = {
    ...COLUMNS[0],
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(confirm)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(confirm)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => {
      const jobId = record.job_id.toString().toLowerCase();

      return jobId.includes(value.toLowerCase());
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  };

  return (
    <div className={styles.historyRoot}>
      <div className={styles.header}>
        <span>Download Super Silo Renders History</span>
      </div>

      <Table columns={COLUMNS} dataSource={dataSource} />
    </div>
  );
};

export default DownloadHistory;
