import React, { PureComponent } from 'react';
import styles from './Button.module.css';

export default class Button extends PureComponent {
  render() {
    const { text, onClick, style, height, white, disabled } = this.props;
    return (
      <button
        className={white ? styles.whiteButton : styles.button}
        style={{
          ...style,
          height: height || '46px',
          opacity: disabled ? 0.3 : 1,
          cursor: disabled ? 'not-allowed' : 'pointer',
        }}
        onClick={onClick}
        disabled={disabled}
      >
        {text}
      </button>
    );
  }
}
