/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component, useState } from 'react';
import styles from './assetRename.module.css';
import Button from '../components/Button';
import { SERVER } from '../config';
import { token, orgId } from '../constants';
import { Timeline, Divider, Button as AntButton, message, Modal } from 'antd';
import {
  LoadingOutlined,
  FileZipOutlined,
  DownloadOutlined,
} from '@ant-design/icons';

const getProgress = (progress, idx, newData) => {
  const newProgress = [...progress];
  newProgress.splice(idx, 1, Object.assign(progress[idx], newData));
  return newProgress;
};

const RenderTimelineItem = (() => {
  const iconMap = {
    procressing: <LoadingOutlined spin />,
    zipping: <FileZipOutlined />,
  };
  const iconColorMap = {
    awaiting: 'grey',
    done: 'green',
  };

  return ({ item, idx, jobId }) => {
    const [clicked, setClicked] = useState(false);

    const onDownloadClick = () => {
      const download = document.getElementById(`download-${idx}`);
      download.href = item.result;
      download.download = `${jobId}-${idx + 1}.zip`;
      download.click();
      setClicked(true);
    };
    return (
      <Timeline.Item
        key={idx}
        color={iconColorMap[item.status]}
        dot={iconMap[item.status]}
      >
        <p>{item.message}</p>
        <p>{`Status: ${
          item.status === 'procressing'
            ? `progress: ${item.processed} / ${item.data.length}`
            : item.status
        }`}</p>
        {item.result && (
          <AntButton
            type={clicked ? 'default' : 'primary'}
            size="small"
            onClick={onDownloadClick}
            icon={<DownloadOutlined />}
          >
            Download Zip File
          </AntButton>
        )}
        <a style={{ display: 'none' }} id={`download-${idx}`} />
      </Timeline.Item>
    );
  };
})();

const groupCount = 20;

class ArFileRename extends Component {
  state = {
    loading: false,
    jobId: '',
    progress: [],
    modalMessage: '',
  };

  onBeforeWindowClose = (e) => {
    e.preventDefault();
    e.returnValue = '';
  };

  onWindowClose = (e) => {
    fetch(`${SERVER}/api/stopArDownload/${this.state.jobId}`);
  };

  componentWillUpdate(newProps, newState) {
    if (newState.loading && !this.state.loading) {
      window.addEventListener('beforeunload', this.onBeforeWindowClose);
      window.addEventListener('unload', this.onWindowClose);
    } else if (!newState.loading && this.state.loading) {
      window.removeEventListener('beforeunload', this.onBeforeWindowClose);
      window.removeEventListener('unload', this.onWindowClose);
    }
  }

  handleClick = async () => {
    const { jobId, loading } = this.state;
    const self = this;
    if (!jobId || loading) return;
    self.setState({ loading: true });
    let response, res;
    try {
      response = await fetch(`${SERVER}/api/initialRenameArJob`, {
        method: 'POST',
        headers: new Headers({ 'Content-Type': 'application/json' }),
        body: JSON.stringify({ jobId, orgId, token }),
      });

      res = await response.json();
    } catch (e) {
      return message.error('Error initial AR download job:', e.toString());
    }

    if (response.status !== 200 || res.error) {
      return message.error(res.error);
    }

    if (res.zipping || res.downloading) {
      self.setState({ loading: false });
      return message.info(res.message);
    }

    if (res.running) {
      return self.setState({ loading: false, modalMessage: res.message });
    }

    const totalCount = res.length;
    let groupIdx = 0;
    const progress = [];
    while (groupCount * groupIdx < totalCount) {
      const startFileIdx = groupCount * groupIdx;
      const groupTotalCount = Math.min(groupCount, totalCount - startFileIdx);
      const endFileIdx = startFileIdx + groupTotalCount;
      progress[groupIdx] = {
        status: 'awaiting',
        message: `Ar files ${startFileIdx + 1} ~ ${endFileIdx}`,
        data: res.slice(startFileIdx, endFileIdx),
        processed: 0,
      };
      groupIdx += 1;
    }

    self.setState({ progress });

    for (let processedIdx = 0; processedIdx < progress.length; ++processedIdx) {
      await new Promise(async (resolve, reject) => {
        const newProgressState = { status: 'procressing' };
        const resolveWithError = () => {
          newProgressState.status = 'error';
          self.setState({
            progress: getProgress(progress, processedIdx, newProgressState),
          });
          resolve();
        };
        self.setState({
          progress: getProgress(progress, processedIdx, newProgressState),
        });

        // start process current group
        const renameJobRes = await fetch(`${SERVER}/api/renameArJob`, {
          method: 'POST',
          headers: new Headers({ 'Content-Type': 'application/json' }),
          body: JSON.stringify({ jobId, data: progress[processedIdx].data }),
        }).then((res) => res.json());

        if (renameJobRes.error) {
          return resolveWithError();
        }

        // process rename promise, will resolve until all the files of current group are processed
        const renameResult = await new Promise(async (resolve, reject) => {
          const interval = setInterval(async () => {
            const process = await fetch(
              `${SERVER}/api/renameArJobProcess/${jobId}`
            ).then((res) => res.json());

            if (process.processed === progress[processedIdx].data.length) {
              clearInterval(interval);
              newProgressState.status = 'zipping';
              newProgressState.processed = process.processed;
              self.setState({
                progress: getProgress(progress, processedIdx, newProgressState),
              });
              resolve({ status: 'done' });
            } else {
              newProgressState.processed = process.processed;
              self.setState({
                progress: getProgress(progress, processedIdx, newProgressState),
              });
            }
          }, 1000);
        });

        if (renameResult.status !== 'done') {
          return resolveWithError();
        }

        // initialZip
        const zipJobRes = await fetch(
          `${SERVER}/api/renameArJobInitialZipping/${jobId}`
        ).then((res) => res.json());
        if (zipJobRes.error) {
          return resolveWithError();
        }

        // process zip promise
        const zipResult = await new Promise(async (resolve, reject) => {
          const interval = setInterval(async () => {
            const zipStatus = await fetch(
              `${SERVER}/api/renameArJobZippingStatus/${jobId}`
            ).then((res) => res.text());
            if (zipStatus === 'done') {
              clearInterval(interval);
              resolve({ status: 'done' });
            }
          }, 1000);
        });
        if (zipResult.status !== 'done') {
          return resolveWithError();
        }
        fetch(`${SERVER}/api/downloadRenameArJobZip/${jobId}`)
          .then((res) => {
            if (res.status !== 200) {
              return resolveWithError();
            }
            return res.blob();
          })
          .then((blob) => {
            if (!blob) return;
            const file = window.URL.createObjectURL(blob);
            newProgressState.result = file;
            newProgressState.status = 'done';
            self.setState(
              {
                progress: getProgress(progress, processedIdx, newProgressState),
              },
              () => resolve()
            );
          });
      });
    }
    this.setState({ loading: false });
  };

  onJobIdInputChange = (e) => {
    const value = e.target.value;
    this.setState({ jobId: value });
  };

  render() {
    const { loading, progress, jobId, modalMessage } = this.state;
    return (
      <div className={styles.container}>
        <div className={styles.header}>
          Get AR render with Naming Convention
        </div>
        <div className={styles.titleContainer}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div
                className={styles.fieldTitle}
                style={{ marginRight: '20px' }}
              >
                Job Id
              </div>
              <input
                style={{ width: '500px' }}
                className={styles.inputField}
                onBlur={this.onJobIdInputChange}
              />
            </div>
            <div style={{ display: 'flex' }}>
              <Button text="Download" onClick={this.handleClick} />

              {loading && (
                <img className={styles.loadingIcon} src="/img/loading.svg" alt="" />
              )}
              <a style={{ display: 'none' }} id="download" />
            </div>
          </div>
        </div>

        {!!progress.length && <Divider />}
        {!!progress.length && (
          <Timeline>
            {progress.map((item, idx) => (
              <RenderTimelineItem item={item} idx={idx} jobId={jobId} />
            ))}
          </Timeline>
        )}
        <Modal
          title={`Server currently running with job ${jobId}`}
          visible={!!modalMessage}
          onCancel={() => this.setState({ modalMessage: '' })}
          onOk={() => {
            this.onWindowClose();
            this.setState({ modalMessage: '' });
          }}
        >
          <p>
            <b>{modalMessage}</b>
          </p>
          <p>
            When close the page while job is running, it will cause the server
            freeze on the previous job, in this case, click ok to terminate the
            current job and restart it again.
          </p>
        </Modal>
      </div>
    );
  }
}

export default ArFileRename;
