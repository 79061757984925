import React, { Component } from "react";
import moment from "moment";
import { message } from "antd";
import Checkbox from "rc-checkbox";

import Divider from "../components/Divider";
import { SERVER } from "../config";
import { token, orgId } from "../constants";
import NotificationSection from "./NotificationSection";
import styles from "./assetRename.module.css";
import RenderErrorsView from "./RenderErrorsView";

class SearchModal extends Component {
  state = {
    secondaryJobStatus: [],
    loading: false,
    selected: {},
    email: "",
    isErrorStateChecked: false,
  };

  displayBatch = (batch, idx) => {
    const { selected } = this.state;

    return (
      <div className={styles.singleRowContainer} key={idx}>
        <div className={styles.singleRow}>
          <Checkbox
            checked={selected[batch._id]}
            onChange={(e) => this.selectBatch(e, batch._id)}
            className={styles.rowCheck}
          />
          <div className={styles.batchTitle}>{batch.name}</div>
          <div className={styles.batchContent}> {batch.user}</div>
          <div className={styles.batchContent}>
            {moment(batch.createdAt).fromNow()}
          </div>
        </div>
      </div>
    );
  };

  selectBatch = (e, id) => {
    let { selected } = this.state;
    let { batches } = this.props;

    const checked = e.target.checked;
    if (checked) {
      if (id) {
        selected[id] = true;
      } else {
        for (let i in batches) {
          selected[batches[i]._id] = true;
        }
      }
    } else {
      if (id) {
        delete selected[id];
      } else {
        selected = {};
      }
    }

    this.setState({ selected });
  };

  isRenamingFlow = () => {
    const { codes } = this.props;

    return (
      codes["Collection Code"] ||
      codes["Item Code"] ||
      codes["Material Code"] ||
      codes["Leg Finish Code"]
    );
  };

  sendRenameRequest = async (selectedJobIds) => {
    const { codes, newCodes } = this.props;

    if (this.isRenamingFlow()) {
      await fetch(`${SERVER}/api/requestUpload`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          jobs: selectedJobIds,
          codes,
          newCodes,
          token,
          orgId,
        }),
      });
    }
  };

  setSelectedImages = async () => {
    const { email, selected } = this.state;

    const selectedJobIds = Object.keys(selected);
    this.setState({ loading: true });

    await this.sendRenameRequest(selectedJobIds);

    fetch(`${SERVER}/api/queryImages`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        selected: selectedJobIds,
        token,
        orgId,
        email: email || "",
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (this.isRenamingFlow()) {
          message.info(
            "Please follow to rename table history in order to upload results to Scene7",
            10
          );
        } else {
          if (res.STATUS === "DONE") {
            message.info(
              "Job is already processed please find it in results table",
              10
            );
          } else {
            message.success(
              "Job processing is started. Results will be saved into history table",
              10
            );
          }
        }

        this.setState({ loading: false });
        this.props.onClose();
      });
  };

  checkForErrors = async () => {
    const { selected } = this.state;
    const selectedJobIds = Object.keys(selected);
    this.setState({ loading: true });

    fetch(`${SERVER}/api/queryErrors`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        selected: selectedJobIds,
        token,
        orgId,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        this.setState({ 
          loading: false,
          isErrorStateChecked: true,
          renderErrors: res
        });
      });
  };

  onEmailChange = (e) => {
    this.setState({ email: e.target.value });
  };

  render() {
    const { batches, onClose } = this.props;
    const { email, loading, isErrorStateChecked, renderErrors, selected } = this.state;

    return (
      <div className={styles.content}>
        {loading && <div className={styles.modalLoader}></div>}
        <div className={styles.closeIcon} onClick={onClose}>
          Close
        </div>

        <div className={styles.modalHeader}>Renders</div>
        <div className={styles.headerContainer}>
          <NotificationSection
            email={email}
            onEmailChange={this.onEmailChange}
          />
        </div>
        <Divider style={{ color: "#000" }} />

        <div className={styles.headerContainer}>
          <div className={styles.singleRow}>
            <Checkbox
              className={styles.rowCheck}
              onChange={(e) => this.selectBatch(e)}
            />
            <div className={styles.batchHeaderTitle}>Name</div>
            <div className={styles.batchHeaderContent}>User</div>
            <div className={styles.batchHeaderContent}>Created At</div>
          </div>
        </div>
        <Divider style={{ color: "#000" }} />

        {batches.map(this.displayBatch)}

        {!isErrorStateChecked && (
          <div className={styles.modalBtnContainer}>
            <button className={styles.upload} onClick={this.checkForErrors}>
              Check for errors
            </button>
          </div>
        )}

        {isErrorStateChecked && (
          <div className={styles.modalBtnContainer}>
            <button className={styles.upload} onClick={this.setSelectedImages}>
              Select
            </button>
          </div>
        )}

        {isErrorStateChecked && (
          <RenderErrorsView
            dataSource={renderErrors}
            jobIds={Object.keys(selected)}
            inModalView
          />
        )}
      </div>
    );
  }
}

export default SearchModal;
