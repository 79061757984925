import React, { Component, Fragment } from "react";
import queryString from "query-string";
import Button from "../components/Button";
import { SERVER } from "../config";

class ImportProducts extends Component {
  handleFileUpload = async (ev) => {
    const file = ev.target.files && ev.target.files[0];
    const form = new FormData();
    form.append("file", file);
    const query = queryString.parse(window.location.search);
    for (const i in query) {
      form.append(i, query[i]);
    }

    await fetch(`${SERVER}/api/uploadCSV`, {
      method: "POST",
      body: form,
    }).then((res) => {
      alert("products imported");
    });
  };

  render() {
    return (
      <Fragment>
        <Button
          text="Import Products"
          style={{ marginLeft: 10 }}
          onClick={() => this.refs["fileUpload"].click()}
        />
        <input
          type="file"
          ref="fileUpload"
          onChange={this.handleFileUpload}
          style={{ display: "none" }}
        />
      </Fragment>
    );
  }
}

export default ImportProducts;
