import React, { useCallback, useEffect, useRef, useState } from "react";
import { Input, Button, Space, Table, Spin, message } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import moment from "moment";

import styles from "./assetRename.module.css";
import { SERVER } from "../config";
import { token, orgId } from "../constants";

const COLUMNS = [
  {
    title: "Job Ids",
    dataIndex: "job_ids",
    key: "job_ids",
  },
  {
    title: "Codes",
    dataIndex: "codes",
    key: "codes",
  },
  {
    title: "New Codes",
    dataIndex: "new_codes",
    key: "new_codes",
  },
  {
    title: "Upload to Scene7",
    dataIndex: "upload",
    key: "upload",
    render: (upload, { onClick }) => (
      <button onClick={onClick}>Upload</button>
    ),
  },
];

const RenameHistory = ({ reload }) => {
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const searchInput = useRef(null);
  const handleSearch = (confirm) => {
    confirm();
  };

  const fetchResults = useCallback(() => {
    fetch(`${SERVER}/api/renameHistory?token=${token}&orgId=${orgId}`)
      .then((res) => res.json())
      .then((history) => {
        const data = history.results.map((i) => i.value);

        setResults(data);
      });
  }, []);

  useEffect(() => {
    fetchResults();
  }, [fetchResults]);

  useEffect(() => {
    if (reload) {
      fetchResults();
    }
  }, [reload, fetchResults]);

  COLUMNS[0] = {
    ...COLUMNS[0],
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(confirm)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(confirm)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => {
      const name = record.name.toString().toLowerCase();
      const jobId = record.job_id.toString().toLowerCase();

      return (
        name.includes(value.toLowerCase()) ||
        jobId.includes(value.toLowerCase())
      );
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  };

  const parseValue = (val) => {
    const newValue = (val || "").replace(/'/g, '"');

    return JSON.parse(`${newValue}`);
  }
  const onScene7Upload = (jobIds, codes, newCodes) => {
    setIsLoading(true);

    fetch(`${SERVER}/api/scene7Upload`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        codes: parseValue(codes),
        newCodes: parseValue(newCodes),
        jobs: parseValue(jobIds),
        token,
        orgId,
      }),
    }).then((res) => res.json())
    .then((res) => {
      setIsLoading(false);

      if (res.STATUS === "DONE") {
        message.success("Sucessfully uploaded", 10);
      } else {
        message.info("Not all jobs are processed yet, please wait", 10);
      }
    })
  };

  let dataSource = results.map((i) => {
    i.onClick = () => {
      if (
        window.confirm(`Do you want upload ${i.job_ids} job result to Scene7?`)
      ) {
        onScene7Upload(i.job_ids, i.codes, i.new_codes);
      }
    };

    i.date = i.date ? moment(new Date(i.date), "DD MM YYYY hh:mm:ss")
      .format("MMM DD YYYY, h:mm:ss a") : "";
    return i;
  });

  return (
    <div className={styles.historyRoot}>
      {isLoading && (
        <div>
          <div className={styles.historyLoaderBG}></div>
          <div className={styles.historyLoader}>
            <Spin spinning={isLoading} />
          </div>
        </div>
      )}

      <div className={styles.header}>
        <span>Rename Assets History</span>
      </div>

      <Table columns={COLUMNS} dataSource={dataSource} />
    </div>
  );
};

export default RenameHistory;
