import React, { useState } from "react";
import { Radio } from "antd";
import styles from "./assetRename.module.css";

const NotificationSection = ({
  email,
  disabled,
  onEmailChange,
}) => {
  const [action, changeAction] = useState("email");

  return (
    <div className={styles.headerContainer}>
      <div>When Job Finished: </div>
      <Radio.Group
        name="radiogroup"
        value={action}
        onChange={(e) => changeAction(e.target.value)}
        disabled={disabled}
      >
        <Radio value="email">Send Email Notification</Radio>
        <Radio value="nothing">Do Nothing</Radio>
      </Radio.Group>

      {action === "email" && (
        <input
          style={{
            width: "300px",
            color: `${disabled ? "rgba(0,0,0,0.25)" : ""}`,
            borderColor: `${disabled ? "rgba(0,0,0,0.25)" : ""}`,
          }}
          className={styles.inputField}
          onChange={onEmailChange}
          value={email}
          disabled={disabled}
        />
      )}
    </div>
  );
}

export default NotificationSection;
